<template>
  <div
    class="industryStructure"
  >
    <!-- <div class="radioBox">
      <el-radio-group
        v-model="tabRadio"
        size="small"
        @change="handleRadioChange"
      >
        <el-radio-button label="产业市场"></el-radio-button>
        <el-radio-button label="产业链条"></el-radio-button>
        <el-radio-button label="专利技术"></el-radio-button>
        <el-radio-button label="产业人才"></el-radio-button>
      </el-radio-group>
    </div> -->
    <div class="newsEchartsBox" v-if="!showPosition" v-loading="graphOptionRadarLoading">
      <div style="padding-top: 1vw">
        <!-- <p v-if="optionList.length === 0" style="margin: 30px 0 0 30px;font-weight: bold;">当前无任何权限 !</p> -->
        <div
          class="industryType"
          v-for="o in optionListRadar"
          :key="o.industry_type"
          style="margin-bottom: 25px"
        >
          <!-- <p
            style="
              margin: 10px 0 0 0;
              color: #337ab7;
              font-weight: bold;
              font-size: 18px;
            "
          >
            {{ o.industry_type }}
          </p> -->
          <div class="industryBox">
            <div
              class="industryBox-item"
              v-for="i in o.option_list"
              :key="i.image_url"
              @click="handleClickIndustryBox(i)"
            >
            <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
              <img
                :src="i.image_url"
                :style="i.is_online == 0 ? 'filter: brightness(20%);' : 'filter: brightness(50%);'"
              />
              <div class="industryBox-text" :style="i.is_online == 0 ? 'color: gray':'color: white'">{{ i.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="showPosition">
      <div class="market">
        <div class="market-top">
          <!-- ▪ <span style="font-weight: bold">简介 </span>
          <span style="font-weight: bold; color: #337ab7"
            >[ {{ industrySelected }} ]</span
          >
          <p class="industryDesc">{{ industryDesc }}</p> -->
          <!-- <p style="font-weight: bold">▪ 产业生态位</p> -->
          <!-- <GraphCategory
            v-loading="treeLoading"
            :nodes="nodes"
            :links="links"
            :eheight="800"
            :longEdge="true"
            style="width: 40vw; height: 80vh"
          ></GraphCategory> -->
          <div
            style="
              height: auto;
              overflow-y: scroll;
              display: flex;
              justify-content: center;
              margin-top: 15px;
            "
            v-loading="positionLoading"
          >
            <div
              class="industryLink_up"
              v-if="links_position.upUpStream.length != 0"
            >
              <p
                style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                "
              >
                上上游
              </p>
              <img
                class="doubleArrow"
                src="@/assets/images/doubleArrow.png"
              />
              <div
                class="industryLink_up-item"
                v-for="u in links_position.upUpStream"
                :key="u.name"
              >
                <div
                  :class="
                    u.name == industrySelected ||
                    industryImportant.indexOf(u.name) != -1
                      ? 'industryLink_up-headSelected'
                      : 'industryLink_up-head'
                  "
                  @mouseover="drawLine(u.name)"
                  @mouseleave="hideLine(u.name)"
                  @click="chooseIndustry(u.name)"
                  @contextmenu="showMenu(u.name)"
                >
                <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink_up-subItem">
                  <div
                    class="industryLink_up-subItem-noChild"
                    v-for="c in u.children"
                    :key="c.name"
                  >
                    <div
                      class="industryLink_up-subItem-head"
                      style="padding: 0 2px 0 2px; color: #58b6e5"
                    >
                      {{ c.name }}
                    </div>
                    <div class="industryLink_up-subItem">
                      <div
                        class="industryLink_up-subItem-noChild"
                        v-for="y in c.children"
                        :key="y.name"
                      >
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink">
              <p
                style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                "
              >
                上游
              </p>
              <img class="doubleArrow" src="@/assets/images/doubleArrow.png" />
              <div
                class="industryLink-item"
                v-for="u in links_position.upStream"
                :key="u.name"
              >
                <div
                  :class="
                    u.name == industrySelected ||
                    industryImportant.indexOf(u.name) != -1
                      ? 'industryLink-headSelected'
                      : 'industryLink-head'
                  "
                  @mouseover="drawLine(u.name)"
                  @mouseleave="hideLine(u.name)"
                  @click="chooseIndustry(u.name)"
                  @contextmenu="showMenu(u.name)"
                >
                <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink-subItem">
                  <div
                    class="industryLink-subItem-noChild"
                    v-for="c in u.children"
                    :key="c.name"
                  >
                    <div
                      :class="
                        c.name == industrySelected ||
                        industryImportant.indexOf(c.name) != -1
                          ? 'industryLink-subItem-headSelected'
                          : 'industryLink-subItem-head'
                      "
                      @mouseover="drawLine(c.name)"
                      @mouseleave="hideLine(c.name)"
                      :id="c.name"
                      @click="chooseIndustry(c.name)"
                      @contextmenu="showMenu(c.name)"
                    >
                    <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                      {{ c.name }}
                    </div>
                    <div class="industryLink-subItem">
                      <div
                        class="industryLink-subItem-noChild"
                        v-for="y in c.children"
                        :key="y.name"
                      >
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink_medium">
              <p
                style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                "
              >
                中游
              </p>
              <img
                class="doubleArrow"
                src="@/assets/images/doubleArrow.png"
              />
              <div
                class="industryLink_medium-item"
                v-for="u in links_position.mediumStream"
                :key="u.name"
              >
                <div
                  :class="
                    u.name == industrySelected ||
                    industryImportant.indexOf(u.name) != -1
                      ? 'industryLink_medium-headSelected'
                      : 'industryLink_medium-head'
                  "
                  @mouseover="drawLine(u.name)"
                  @mouseleave="hideLine(u.name)"
                  @click="chooseIndustry(u.name)"
                  @contextmenu="showMenu(u.name)"
                >
                <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink_medium-subItem">
                  <div
                    class="industryLink_medium-subItem-noChild"
                    v-for="c in u.children"
                    :key="c.name"
                  >
                    <div
                      :class="
                        c.name == industrySelected ||
                        industryImportant.indexOf(c.name) != -1
                          ? 'industryLink_medium-subItem-headSelected'
                          : 'industryLink_medium-subItem-head'
                      "
                      @mouseover="drawLine(c.name)"
                      @mouseleave="hideLine(c.name)"
                      @click="chooseIndustry(c.name)"
                      @contextmenu="showMenu(c.name)"
                      :id="c.name"
                    >
                    <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                      {{ c.name }}
                    </div>
                    <div class="industryLink_medium-subItem">
                      <div
                        class="industryLink_medium-subItem-noChild"
                        v-for="y in c.children"
                        :key="y.name"
                      >
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink_down">
              <p
                style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                "
              >
                下游
              </p>
              <img
                class="doubleArrow"
                src="@/assets/images/doubleArrow.png"
              />
              <div
                class="industryLink_down-item"
                v-for="u in links_position.downStream"
                :key="u.name"
              >
                <div
                  :class="
                    u.name == industrySelected ||
                    industryImportant.indexOf(u.name) != -1
                      ? 'industryLink_down-headSelected'
                      : 'industryLink_down-head'
                  "
                  @click="chooseIndustry(u.name)"
                  @contextmenu="showMenu(u.name)"
                >
                <vue-context-menu :contextMenuData="contextMenuData"
        @toGraph="toGraph()"></vue-context-menu>
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink_down-subItem">
                  <div
                    class="industryLink_down-subItem-noChild"
                    v-for="c in u.children"
                    :key="c.name"
                  >
                    <div class="industryLink_down-subItem-head">
                      {{ c.name }}
                    </div>
                    <div class="industryLink_down-subItem">
                      <div
                        class="industryLink_down-subItem-noChild"
                        v-for="y in c.children"
                        :key="y.name"
                      >
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ▪ <span style="font-weight: bold">简介 </span> -->
          <span style="font-weight: bold; color: #337ab7"
            >[ {{ industrySelected }} ]</span
          >
          <p class="industryDesc">
            <!-- <span style="font-weight: bold; color: #337ab7;margin-right: 10px;"
            >[ {{ industrySelected }} ]</span
          > -->
          {{ industryDesc }}</p>
        </div>
        <!-- <div class="market-bottom">
          <div style="position: relative">
            <p style="font-weight: bold">▪ 地区分布</p>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 90%;
                position: relative;
              "
            >
              <div class="selectType" style="left: 1.5vw; top: 75%">
                <div
                  class="img"
                  @click="changeGlobalOrChina(1, 1)"
                  v-if="mapTabMarket == 2"
                >
                  <div :class="mapTabMarket == 1 ? 'high' : ''"></div>
                  <img src="@/assets/images/china1.png" alt />
                </div>
                <div class="img" @click="changeGlobalOrChina(2, 1)" v-else>
                  <div :class="mapTabMarket == 2 ? 'high' : ''"></div>
                  <img src="@/assets/images/world1.png" alt />
                </div>
              </div>
              <world-new
                v-if="mapTabMarket == 2"
                :data="worldListMarket"
                eheight="30vh"
                style="width: 25vw; margin-left: 1vw; margin-top: 1vw"
              ></world-new>
              <MapIndustry
                v-if="mapTabMarket == 1"
                style="width: 25vw; margin-left: 1vw; margin-top: 1vw"
                eheight="30vh"
                :data="mapLatentMarket"
              ></MapIndustry>
            </div>
          </div>
          <div style="width: 55vw">
            <p
              style="
                font-weight: bold;
                display: flex;
                justify-content: space-between;
              "
            >
              ▪ 公司列表
            </p>
            <el-table
              :data="downstreamCompanyList"
              stripe
              style="width: 100%; margin-top: 10px; margin-bottom: 20px"
              border
              height="400"
              @row-click="getCompanyInfo"
            >
              <el-table-column prop="name" label="公司" width="120">
              </el-table-column>
              <el-table-column prop="loc_province" label="地区">
              </el-table-column>
              <el-table-column prop="scale" label="规模" sortable>
              </el-table-column>
              <el-table-column prop="technology" label="技术" sortable>
              </el-table-column>
              <el-table-column prop="customer" label="客户" sortable>
              </el-table-column>
              <el-table-column prop="brand" label="品牌" sortable>
              </el-table-column>
              <el-table-column prop="risk" label="风险" sortable>
              </el-table-column>
              <el-table-column prop="comprehensive" label="综合" sortable>
              </el-table-column>
            </el-table>
          </div>
        </div> -->
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '产业链条'">
      <div style="width: 45%">
        <GraphCategory
          v-loading="treeLoading"
          :nodes="nodes"
          :links="links"
          :eheight="800"
          :longEdge="true"
          style="width: 40vw; height: 100%"
        ></GraphCategory>
        <div class="nodeDesc">{{ nodeDesc }}</div>
        <el-button
          style="position: absolute; margin-left: 40vw; top: 11vw; z-index: 5"
          type="primary"
          size="mini"
          @click="showCost = true"
          >产业成本</el-button
        >
      </div>
      <div style="position: relative; width: 55%">
        <div class="selectType">
          <div class="img" @click="changeGlobalOrChina(1, 2)" v-if="tab == 2">
            <div :class="tab == 1 ? 'high' : ''"></div>
            <img src="@/assets/images/china1.png" alt />
          </div>
          <div class="img" @click="changeGlobalOrChina(2, 2)" v-else>
            <div :class="tab == 2 ? 'high' : ''"></div>
            <img src="@/assets/images/world1.png" alt />
          </div>
        </div>
        <world-new
          v-if="tab == 2"
          :data="worldListMarket"
          eheight="30vh"
          style="width: 25vw; margin-left: 6.5vw"
        ></world-new>
        <MapIndustry
          v-if="tab == 1"
          style="width: 25vw; margin-left: 6.5vw"
          eheight="30vh"
          :data="mapLatentMarket"
        ></MapIndustry>
        <p
          style="
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 10px;
          "
        >
          ▪ 公司列表
        </p>
        <el-table
          :data="downstreamCompanyList"
          stripe
          style="width: 100%; margin-bottom: 20px"
          border
          height="400"
          @row-click="getCompanyInfo"
        >
          <el-table-column prop="name" label="公司" width="120">
          </el-table-column>
          <el-table-column prop="loc_province" label="地区"> </el-table-column>
          <el-table-column prop="scale" label="规模" sortable>
          </el-table-column>
          <el-table-column prop="technology" label="技术" sortable>
          </el-table-column>
          <el-table-column prop="customer" label="客户" sortable>
          </el-table-column>
          <el-table-column prop="brand" label="品牌" sortable>
          </el-table-column>
          <el-table-column prop="risk" label="风险" sortable> </el-table-column>
          <el-table-column prop="comprehensive" label="综合" sortable>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '专利技术'">
      <div class="tech">
        <div class="tech-top">
          <GraphCategory
            v-loading="treeLoading"
            :nodes="nodes"
            :links="links"
            :eheight="800"
            :longEdge="true"
            style="width: 45vw; height: 80vh"
          ></GraphCategory>
          <div style="position: relative">
            <div class="selectType" style="top: 22vh; margin-left: 50px">
              <div
                class="img"
                style="width: 60px; height: 45px"
                @click="changeGlobalOrChina(1, 3)"
                v-if="mapTabTech == 2"
              >
                <div :class="mapTabTech == 1 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/china1.png"
                  alt
                />
              </div>
              <div
                class="img"
                @click="changeGlobalOrChina(2, 3)"
                style="width: 60px; height: 45px"
                v-else
              >
                <div :class="mapTabTech == 2 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/world1.png"
                  alt
                />
              </div>
            </div>
            <world-new
              v-if="mapTabTech == 2"
              :data="worldListTech"
              eheight="30vh"
              style="width: 40vw; margin-left: 6.5vw; margin-top: 0vw"
            ></world-new>
            <MapPatent
              v-loading="patentScatterLoading"
              v-if="mapTabTech == 1"
              style="width: 40vw; margin-left: 6.5vw; margin-top: 0vw"
              eheight="30vh"
              :data="mapLatentTech"
            ></MapPatent>
            <CompanyPatentBar
              v-loading="barLoading"
              :apiDataBarChart="companyPatentCount"
              style="width: 98%; margin-top: 5%"
            ></CompanyPatentBar>
          </div>
        </div>
        <div class="tech-bottom">
          <div style="display: flex">
            <industry-patent-echarts
              style="margin-left: 10px"
              v-loading="patentCountLoading"
              :apiDataLineChart="patentEchartsData"
            ></industry-patent-echarts>
            <news-keyword-cloud
              v-loading="keywordLoading"
              :apiDataLineChart="wordCloudData"
            ></news-keyword-cloud>
          </div>
          <el-radio-group
            v-model="patentParams.filter_type"
            size="small"
            style="margin-left: 15px; margin-top: 15px"
            @change="handleTypeChange"
          >
            <el-radio-button label="相关公司专利"></el-radio-button>
            <el-radio-button label="相关产品专利"></el-radio-button>
          </el-radio-group>
          <div
            v-for="p in patent_list"
            :key="p.num"
            style="width: 98%; margin-left: 1%"
          >
            <patent-card :patent="p"></patent-card>
          </div>
          <div class="mores">
            <p class="mores-text" @click="showMore">加载更多</p>
          </div>
        </div>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '产业人才'">
      <div class="tech">
        <div class="tech-top">
          <GraphCategory
            v-loading="treeLoading"
            :nodes="nodes"
            :links="links"
            :eheight="800"
            :longEdge="true"
            style="width: 45vw; height: 80vh"
          ></GraphCategory>
          <div style="position: relative">
            <div class="selectType" style="top: 22vh; margin-left: 50px">
              <div
                class="img"
                style="width: 60px; height: 45px"
                @click="changeGlobalOrChina(1, 3)"
                v-if="mapTabTech == 2"
              >
                <div :class="mapTabTech == 1 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/china1.png"
                  alt
                />
              </div>
              <div
                class="img"
                @click="changeGlobalOrChina(2, 3)"
                style="width: 60px; height: 45px"
                v-else
              >
                <div :class="mapTabTech == 2 ? 'high' : ''"></div>
                <img
                  style="width: 60px; height: 45px"
                  src="@/assets/images/world1.png"
                  alt
                />
              </div>
            </div>
            <world-new
              v-if="mapTabTech == 2"
              :data="worldListTech"
              eheight="30vh"
              style="width: 40vw; margin-left: 6.5vw; margin-top: 0vw"
            ></world-new>
            <MapTalent
              v-if="mapTabTech == 1"
              style="width: 40vw; margin-left: 6.5vw; margin-top: 0vw"
              eheight="30vh"
              :data="talentData"
            ></MapTalent>
            <CompanyPostBar
              v-loading="postCompanyLoading"
              :apiDataBarChart="postCompanyCount"
              style="width: 98%; margin-top: 5%"
            ></CompanyPostBar>
          </div>
        </div>
        <div class="tech-bottom">
          <div style="display: flex">
            <IndustryTalentEcharts
              style="margin-left: 10px"
              v-loading="talentCountLoading"
              :apiDataLineChart="talentEchartsData"
            ></IndustryTalentEcharts>
            <PostCountBar
              v-loading="postCountLoading"
              :apiDataBarChart="postCount"
              style="width: 50%; margin-left: 20px"
            ></PostCountBar>
            <!-- <CompanyPostBar
              :apiDataBarChart="postCompanyCount"
              style="width: 50%; margin-top: 5%"
            ></CompanyPostBar> -->
          </div>
          <el-table
            :data="post_list"
            stripe
            style="width: 97%; margin: 20px 1.5% 20px 1.5%"
            border
            @row-click="showPostDetail"
          >
            <el-table-column prop="company_name" label="公司" width="180">
            </el-table-column>
            <el-table-column prop="name" label="岗位" width="160">
            </el-table-column>
            <el-table-column prop="address" label="地点"> </el-table-column>
            <el-table-column prop="num" label="招聘人数" sortable width="120">
            </el-table-column>
            <el-table-column prop="salary" label="薪酬" sortable>
            </el-table-column>
            <el-table-column prop="release_date" label="时间" sortable>
            </el-table-column>
          </el-table>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin: 10px 0 15px 0;
            "
          >
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="post_params.page"
              :total="post_total"
              :page-size="post_params.page_size"
              :page-count="5"
              @current-change="handlePostPageChange"
            >
            </el-pagination>
          </div>
          <!-- <div class="more">
            <p class="more-text" @click="showMore">加载更多</p>
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog title="公司信息" :visible.sync="showInfo" width="70%">
      <div id="introduce" class="introduce">
        <!-- <img class="close" src="@/assets/img/close.png" @click="showIntroduce = false" /> -->
        <div class="detail">
          <div class="detail-mask" v-if="false">
            <p>当前未收录该公司的详细信息</p>
          </div>
          <div class="detailTop">
            <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
            <div class="compLogo">
              <p>{{ compNameForLogo }}</p>
            </div>
            <div class="des">
              <h1>
                {{ companyInfo.entity_name }}
                <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
              </h1>
              <p>
                <span>电话：{{ companyInfo.telephone || "暂无" }}</span>
                <span style="cursor: pointer" @click="goto(companyInfo.website)"
                  >官网： {{ companyInfo.website || "暂无" }}</span
                >
              </p>
              <p>
                <span>邮箱：{{ companyInfo.email || "暂无" }}</span>
                <span>地址：{{ companyInfo.office_addr || "暂无" }}</span>
              </p>
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <td>法定代表人</td>
                <td>{{ companyInfo.legal_representative || "暂无" }}</td>
                <td>公司类别</td>
                <td>{{ companyInfo.company_type || "暂无" }}</td>
                <td>成立日期</td>
                <td>{{ companyInfo.founded_date || "暂无" }}</td>
              </tr>
              <tr>
                <td>注册资本</td>
                <td>{{ companyInfo.regis_capital || "暂无" }}</td>
                <td>纳税人识别号</td>
                <td>{{ companyInfo.regis_code || "暂无" }}</td>
                <td>所属地区</td>
                <td>{{ companyInfo.headquater || "暂无" }}</td>
              </tr>
              <tr>
                <td>员工人数</td>
                <td>{{ companyInfo.employees || "暂无" }}</td>
                <td>公司英文</td>
                <td>{{ companyInfo.name_en || "暂无" }}</td>
                <td>是否上市</td>
                <td>{{ companyInfo.listed ? companyInfo.listed : "未知" }}</td>
              </tr>
              <!-- <tr>
            <td>Moody’s</td>
            <td>{{data['commercial_info/Moody’s']||'暂无'}}</td>
            <td>income_structure</td>
            <td>{{data['commercial_info/income_structure']||'暂无'}}</td>
            <td>{{"S&P"}}</td>
            <td>{{data['commercial_info/S&P']||'暂无'}}</td>
            </tr>-->
              <tr>
                <td>产业标签</td>
                <td colspan="5" style="text-align: left; word-break: break-all">
                  {{ companyInfo.industry || "暂无" }}
                </td>
              </tr>
              <tr>
                <td>经营范围</td>
                <td colspan="5" style="text-align: left; word-break: break-all">
                  {{ companyInfo.domain || "暂无" }}
                </td>
              </tr>
              <tr>
                <td>描述</td>
                <td colspan="5" style="text-align: left; word-break: break-all">
                  {{ companyInfo.company_profile || "暂无" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <company-detail
          :tagList="tagList"
          :productList="productList"
          :industryPaquList="industryPaquList"
          :chartDataLink="chartDataLink"
          :chartLinkShortName="chartLinkShortName"
          :onlySub="onlySub"
          :chartDataFinancial="chartDataFinancial"
          :chartDataProduct="chartDataProduct"
          :chartProductYearList="chartProductYearList"
          :detailTrTopVal="detailTrTopVal"
          :patentEchartsData="patentEchartsData"
          :wordCloudData2="wordCloudData2"
          :patentListData="patentListData"
          :collapseActiveNames="collapseActiveNames"
          @showMorePatent="showMorePatent()"
          @collapseActive="handleCollapseActive"
        ></company-detail>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false" type="primary">关 闭</el-button>
    </div> -->
    </el-dialog>
    <el-dialog title="产业成本" :visible.sync="showCost">
      <el-table :data="tableCost">
        <el-table-column label="新能源车热泵系统">
          <el-table-column prop="part" label="零部件"></el-table-column>
          <el-table-column prop="price" label="单车价格(元)"></el-table-column>
        </el-table-column>
        <el-table-column label="2020">
          <el-table-column prop="in20" label="国内"></el-table-column>
          <el-table-column prop="out20" label="国际"></el-table-column>
        </el-table-column>
        <el-table-column label="2025">
          <el-table-column prop="in25" label="国内"></el-table-column>
          <el-table-column prop="out25" label="国际"></el-table-column>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="评分明细"
      width="98%"
      top="9vh"
      :visible.sync="showScoreDetail"
      :destroy-on-close="false"
    >
      <el-table
        :data="downstreamCompanyList"
        stripe
        style="
          width: 100%;
          margin-top: 10px;
          margin-bottom: 20px;
          overflow-x: auto !important;
        "
        border
        height="500"
      >
        <el-table-column prop="full_name" label="公司名" width="220" fixed>
        </el-table-column>
        <el-table-column prop="short_name" label="简称" width="120">
        </el-table-column>
        <el-table-column prop="board" label="板块" width="100">
        </el-table-column>
        <el-table-column
          prop="registered_capital"
          label="注册资本"
          sortable
          width="150"
        >
        </el-table-column>
        <el-table-column prop="staff_num" label="员工数量" sortable width="120">
        </el-table-column>
        <el-table-column prop="ratepayer_num" label="纳税人识别号" width="200">
        </el-table-column>
        <el-table-column prop="address" label="地址" width="200">
        </el-table-column>
        <el-table-column
          prop="last_income"
          label="上一年营收"
          sortable
          width="170"
        >
        </el-table-column>
        <el-table-column
          prop="last_income_ratio"
          label="上一年营收增长率"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="last_profit"
          label="上一年净利润"
          sortable
          width="170"
        >
        </el-table-column>
        <el-table-column
          prop="last_profit_ratio"
          label="上一年净利润增长率"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="last_coreproduct"
          label="上一年核心产品"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="last_coreproduct_income"
          label="上一年核心产品营收"
          sortable
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="patent_sum"
          label="专利数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="last_patent_num"
          label="上一年专利数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="down_customer_num"
          label="下游公司数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="subsidiary_num"
          label="子公司数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="positive_num"
          label="正面舆情数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="half_positive_num"
          label="半年内正面舆情数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="negative_num"
          label="负面舆情数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="half_negative_num"
          label="半年内负面舆情数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="legal_num"
          label="法律文书数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="last_legal_num"
          label="一年内法律文书数量"
          sortable
          width="100"
        >
        </el-table-column>
        <el-table-column prop="industry_category" label="行业种类" width="100">
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="招聘信息" :visible.sync="showPost">
      <p class="postDetail-post">
        {{ postInfoDetail.name }}
        <span>{{ postInfoDetail.salary }}</span>
      </p>
      <p class="postDetail-company">{{ postInfoDetail.company_name }}</p>
      <p class="postDetail-info">
        <span> {{ postInfoDetail.region }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.experience }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.education }} </span>
        <span class="postDetail-line">|</span>
        <span> 招{{ postInfoDetail.num ? postInfoDetail.num : "1" }}人 </span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.release_date }} 发布</span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.source }}</span>
      </p>
      <div v-if="postInfoDetail.key_word">
        <el-tag
          type="primary"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.keyword_tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <div v-if="postInfoDetail.other_label">
        <el-tag
          type="success"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <p style="margin-top: 10px" v-if="postInfoDetail.post_category">
        职能类别：{{ postInfoDetail.post_category }}
      </p>
      <p
        class="postDetail-position_info"
        v-html="postInfoDetail.position_info"
      ></p>
      <p class="postDetail-url">
        <a
          :href="postInfoDetail.url"
          style="color: #5c7bd9; font-weight: bold; font-size: 16px"
          target="_blank"
          >前往原网页</a
        >
      </p>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import GraphCategory from "../components/GraphCategory.vue";
import WorldNew from "../components/WorldNew.vue";
import MapIndustry from "../components/MapIndustry.vue";
import MapPatent from "../components/MapPatent.vue";
import MapTalent from "../components/MapTalent.vue";
import PatentCard from "../components/PatentCard.vue";
import ev from "../json/industry_link/ev.json";
import yiliao from "../json/industry_link/yiliao.json";
import yiliaoqixie from "../json/industry_link/yiliaoqixie.json";
import LinkEcharts from "../components/LinkEcharts";
import IndustryPatentEcharts from "../components/IndustryPatentEcharts.vue";
import IndustryTalentEcharts from "../components/IndustryTalentEcharts.vue";
import NewsKeywordCloud from "../components/NewsKeywordCloud.vue";
import CompanyPatentBar from "@/components/CompanyPatentBar.vue";
import CompanyPostBar from "@/components/CompanyPostBar.vue";
import PostCountBar from "@/components/PostCountBar.vue";
import FinancialEcharts from "../components/FinancialEcharts";
import ProductEcharts from "../components/ProductEcharts";
import PatentEcharts from "../components/PatentEcharts.vue";
import KeywordCloud from "../components/KeywordCloud.vue";
import PatentList from "../components/PatentList.vue";
import CompanyDetail from "../components/CompanyDetail.vue";

export default {
  components: {
    GraphCategory,
    WorldNew,
    MapIndustry,
    PatentCard,
    MapPatent,
    MapTalent,
    IndustryPatentEcharts,
    NewsKeywordCloud,
    CompanyPatentBar,
    CompanyPostBar,
    IndustryTalentEcharts,
    PostCountBar,
    LinkEcharts,
    FinancialEcharts,
    ProductEcharts,
    PatentEcharts,
    KeywordCloud,
    PatentList,
    CompanyDetail,
  },
  data() {
    return {
      env: "",
      optionListRadar: [],
      showPosition: false,
      industryImportantObjArr: [],
      showMoreText: "加载更多",
      patentEchartsData: [],
      patentListData: [],
      postCountLoading: false,
      wordCloudData2: {},
      talentData: [],
      postLoading: false,
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      tagList: [],
      productList: [],
      industryPaquList: [],
      talentEchartsData: {},
      chartDataLink: {},
      chartDataProduct: {},
      chartProductYearList: [],
      chartLinkShortName: "",
      onlySub: false, // 是否只拥有子公司
      talentCountLoading: false,
      lineChartProductVisible: false,
      post_list: [
        {
          name: "比亚迪",
          post: "工程师",
          address: "深圳",
          num: "5",
          salary: "10K",
          release_date: "2021-09-01",
        },
      ],
      post_params: {
        industry: "",
        company_id: "",
        page: 1,
        page_size: 10,
        industry_node: "",
        post_classification: "",
      },
      post_total: 0,
      link_industry: "",
      patent_list: [],
      postCompanyLoading: false,
      barLoading: false,
      downstreamLoading: false,
      patentLoading: false,
      patentScatterLoading: false,
      patentCountLoading: false,
      keywordLoading: false,
      downstreamCompanyList: [],
      downstreamCompanyListChina: [],
      downstreamCompanyListGlobal: [],
      industryDesc: "",
      industrySelected: "",
      industryImportant: [""],
      domain_id: "",
      nodeDesc: "",
      tabRadio: "产业市场",
      tableLoading: false,
      treeLoading: false,
      compNameForLogo: "",
      companyInfo: {
        entity_name: "",
        telephone: "",
        website: "",
        email: "",
        office_addr: "",
        description: "",
        label: "",
        legal_representative: "",
        company_type: "",
        founded_date: "",
        regis_capital: "",
        regis_code: "",
        headquater: "",
        employees: "",
        name_en: "",
        listed: "",
        domain: "",
        stock_code: "",
        industry: "",
        company_profile: "",
      },
      detailTrTopVal: [],
      tableCost: [
        {
          part: "电动压缩机",
          price: "1200",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "四通换向阀",
          price: "150",
          in20: "0.3",
          out20: "0.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "换热器",
          price: "300",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
      ],
      postInfoDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
      },
      showInfo: false,
      showCost: false,
      tabsValue: "1",
      isFirst: true,
      tableData: [],
      tableDataChina: [],
      tableDataGlobal: [],
      newsEchartsData: {},
      patentEchartsData: [],
      wordCloudData: [],
      permissions: "",
      nodes: [],
      mapTabMarket: 1,
      mapTabTech: 1,
      tab: 1,
      mapLatent: [],
      mapLatentMarket: [],
      mapLatentTech: [],
      worldList: [],
      worldListMarket: [],
      worldListTech: [],
      links: [],
      optionList: [],
      loading: false,
      showScoreDetail: false, // 是否展示评分项明细
      company_id: "",
      patentParams: {
        industry: "",
        domain_id: "",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
      },
      formData: {
        full_name: "xxx公司",
        is_listed: "1",
        board: "深圳主板",
        income: "",
      },
      lineChartFinancialVisible: false,
      chartDataFinancial: [],
      companyPatentCount: {
        legend: [],
        data: [],
        name: {},
      },
      selectedItem: {},
      postCompanyCount: {
        // 产业人才排行
        legend: [],
        data: [],
        name: {},
      },
      postCount: {
        // 岗位招聘排行
        legend: [],
        data: [],
        name: {},
      },
      links_position: {
        upUpStream: [],
        upStream: [],
        mediumStream: [],
        downStream: [],
      },
      positionLoading: false,
      showPost: false,
      newsList: [],
      line1: "",
      line2: "",
      line3: "",
      line4: "",
      line5: "",
      line6: "",
      line7: "",
      line8: "",
      line9: "",
      line10: "",
      line11: "",
      contextMenuData: {
         menuName: 'demo',
         //菜单显示的位置
         axis: {
           x: null,
           y: null
         },
         //菜单选项
         menulists: [{
           fnHandler: 'toGraph', //绑定事件
           icoName: 'fa fa-home fa-fw', //icon图标
           btnName: '前往产业图谱' //菜单名称
         }]
       }
    };
  },
  computed: {
    isOrNot() {
      if (this.formData.is_listed == 1) {
        return "是";
      } else {
        return "否";
      }
    },
  },
  methods: {
    showMenu (i) {
      this.selectedItem = {}
      this.optionListRadar[0].option_list.forEach(o => {
        if (o.name == i) {
          this.selectedItem = o
        }
      })
      console.log("selectedItem", this.selectedItem)
      if (this.industryImportant.indexOf(i) != -1) { // 判断选择的是否是重点产业链节点，是则显示右键菜单，否则无响应
        event.preventDefault() // 阻止默认的右键菜单事件
        var x = event.clientX
        var y = event.clientY
          // Get the current location
        this.contextMenuData.axis = {
          x, y
        }
        console.log("showMenu", i)
        console.log("optionListRadar", this.optionListRadar[0].option_list)
      } else {
        event.preventDefault()
      }
    },
    // 前往产业图谱
    toGraph () {
      sessionStorage.setItem("index_industry", JSON.stringify(this.selectedItem))
      console.log("前往产业图谱")
      this.$router.push("/category_graph")
    },
    handleContext() {
      console.log("handleContext")
    },
    // 点击产业选项
    handleClickIndustryBox(i) {
      sessionStorage.setItem("index_industry", JSON.stringify(i));
      this.$store.commit("changeIndexIndustry", i.name);
      EventBus.$emit("index_industry", i);
      console.log("emit [index_industry]", i);
      // this.$router.push("/industry_structure");
      // this.showPosition = true
      this.$router.push("/category_graph?from=industry_structure")
    },
    // 获取产业选项
    getIndustryGraphOptionRadar() {
      this.optionListRadar = [];
      this.graphOptionRadarLoading = true
      api
        .getIndustryGraphOptionRadar()
        .then((res) => {
          console.log("option111", res);
          // this.optionList = res.data
          let industry_graph = cookie.getCookie("industry_graph")
          let industry_graph_array = industry_graph.split(",")
          console.log("industry_graph_array", industry_graph_array)
          if (this.env != 'pro') { // 判断环境（非生产环境不论is_online为多少都展示）
            let arr_tmp = {
              industry_type: "新能源汽车",
              option_list: []
            }
            res.data[0].option_list.forEach(i => {
              if (industry_graph_array.indexOf(i.code) != -1) {
                arr_tmp.option_list.push(i)
              }
            })
            this.optionListRadar.push(arr_tmp);
            // this.optionListRadar.push(res.data[0]);
          } else { // 判断环境（生产环境只展示is_online=1的项）
            let arr_tmp = {
              industry_type: "新能源汽车",
              option_list: []
            }
            res.data[0].option_list.forEach(i => {
              if (i.is_online == 1 && industry_graph_array.indexOf(i.code) != -1) {
                arr_tmp.option_list.push(i)
              }
            })
            this.optionListRadar.push(arr_tmp)
          }
          
          this.graphOptionRadarLoading = false
        })
        .catch((err) => {
          // console.log(err);
          this.graphOptionRadarLoading = false
        });
    },

    // 点击产业生态位
    chooseIndustry(val) {
      console.log("点击产业生态位", val)
      this.industryImportantObjArr.forEach((i) => {
        // EventBus.$emit("index_industry", val)
        if (i.name == val) {
          console.log("生态位", i);
          sessionStorage.setItem("index_industry", JSON.stringify(i));
          this.$store.commit("changeIndexIndustry", val)
          console.log("state", this.$store.state.index_industry)
          EventBus.$emit("index_industry", i);
          console.log("生态位2")
          EventBus.$emit("industry_position", i);
          this.line1.remove();
          this.line2.remove();
          this.line3.remove();
          this.line4.remove();
          this.line5.remove();
          this.line6.remove();
          this.line7.remove();
          this.line8.remove();
          this.line9.remove();
          this.line10.remove();
          this.line11.remove();
        }
      });
    },
    // 连线初始化
    lineInit() {
      this.line1 = new LeaderLine(
        document.getElementById("正极材料"),
        document.getElementById("动力锂电池系统"),
        // this.$refs["热泵空调"],
        // this.$refs["锂电池生产设备"],
        {
          size: 2,
          // color: "black",
          hide: true,
        }
      );
      this.line2 = new LeaderLine(
        document.getElementById("负极材料"),
        document.getElementById("动力锂电池系统"),
        {
          size: 2,
          // color: "black",
          hide: true,
        }
      );
      this.line3 = new LeaderLine(
        document.getElementById("锂电池生产设备"),
        document.getElementById("动力锂电池系统"),
        {
          size: 2,
          // color: "black",
          hide: true,
        }
      );
      this.line4 = new LeaderLine(
        document.getElementById("电解液"),
        document.getElementById("动力锂电池系统"),
        {
          size: 2,
          // color: "black",
          hide: true,
        }
      );
      this.line5 = new LeaderLine(
        document.getElementById("隔膜"),
        document.getElementById("动力锂电池系统"),
        {
          size: 2,
          // color: "black",
          hide: true,
        }
      );
      this.line6 = new LeaderLine(
        document.getElementById("动力锂电池系统"),
        document.getElementById("新能源汽车"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
      this.line7 = new LeaderLine(
        document.getElementById("热泵空调"),
        document.getElementById("新能源汽车"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
      this.line8 = new LeaderLine(
        document.getElementById("汽车雷达"),
        document.getElementById("新能源汽车"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
      this.line9 = new LeaderLine(
        document.getElementById("车联网"),
        document.getElementById("智能座舱"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
      this.line10 = new LeaderLine(
        document.getElementById("车联网"),
        document.getElementById("智能汽车"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
      this.line11 = new LeaderLine(
        document.getElementById("智能座舱"),
        document.getElementById("智能汽车"),
        {
          size: 2,
          color: "purple",
          hide: true,
        }
      );
    },
    // 连线
    drawLine(val) {
      // if (this.patentParams.industry != "ele_car") return;
      if (val == "动力锂电池系统" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'power_battery')) {
        this.line1.show("draw");
        this.line2.show("draw");
        this.line3.show("draw");
        this.line4.show("draw");
        this.line5.show("draw");
        this.line6.show("draw");
      }
      if (val == "热泵空调" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'air_conditioning')) {
        this.line7.show("draw");
      }
      if (val == "锂电池生产设备" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == "lithium_battery_production")) {
        this.line3.show("draw");
      }
      if (val == "汽车雷达" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'car_radar')) {
        this.line8.show("draw");
      }
      if (val == "车联网" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'car_networking') || val == "智能座舱" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'smart_cockpit')) {
        this.line9.show("draw");
      }
      if (val == "智能汽车" && (this.patentParams.industry == 'ele_car' ||this.patentParams.industry == 'smart_car')) {
        this.line10.show("draw");
        this.line11.show("draw");
      }
    },
    // 隐藏线
    hideLine(val) {
      // if (this.patentParams.industry != "ele_car") return;
      if (val == "动力锂电池系统") {
        this.line1.hide("draw");
        this.line2.hide("draw");
        this.line3.hide("draw");
        this.line4.hide("draw");
        this.line5.hide("draw");
        this.line6.hide("draw");
      }
      if (val == "热泵空调") {
        this.line7.hide("draw");
      }
      if (val == "锂电池生产设备") {
        this.line3.hide("draw");
      }
      if (val == "汽车雷达") {
        this.line8.hide("draw");
      }
      if (val == "车联网" || val == "智能座舱") {
        this.line9.hide("draw");
      }
      if (val == "智能汽车") {
        this.line10.hide("draw");
        this.line11.hide("draw");
      }
    },
    handleCollapseActive(val) {
      this.collapseActiveNames = val;
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.patentLoading = true
      api
        .getCompanyBaseInfo(params)
        .then((res) => {
          console.log("专利", res);
          this.patentEchartsData = res.data[0].year_count_list;
          this.wordCloudData2 = res.data[0].patent_keywords;
          // this.patentLoading = false
        })
        .catch((err) => {
          // this.patentLoading = false
        });
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        // this.patentLoading = false;
      });
    },
    // 加载更多专利
    showMorePatent() {
      this.patentParams.page += 1;
      console.log("page", this.patentParams.page);
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    handleCollapse(val) {
      console.log("collapse", val);
      if (val.indexOf("3") != -1) {
        EventBus.$emit("financial_rerender", 1);
      }
      if (val.indexOf("4") != -1) {
        EventBus.$emit("product_rerender", 1);
      }
    },
    // 获取公司company_id
    getCompanyId(full_name) {
      let params = {
        full_name: full_name,
      };
      api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
    },
    // 监听招聘岗位排行点击
    listenCompanyPostRank() {
      EventBus.$on("company_post_rank", (data) => {
        this.post_params.post_classification = data;
        this.post_params.page = 1;
        this.post_params.company_id = "";
        this.getPostInfo();
      });
    },
    // 获取招聘岗位统计
    getPostCount(node) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.postCountLoading = true;
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
          this.postCountLoading = false;
        })
        .catch((err) => {
          this.postCountLoading = false;
        });
    },
    // 获取招聘散点分布
    getPostScatter(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.talentData = [];
      api.getPostScatter(params).then((res) => {
        // console.log("招聘散点", res)
        this.talentData = res.data;
      });
    },
    // 获取招聘月度排行
    getPostMonthRank(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
      });
    },
    // 点击招聘信息
    showPostDetail(val) {
      // console.log(val)
      this.getPostDetail(val.id);
      this.postInfoDetail = {};
      this.postInfoDetail.tags = [];
      this.postInfoDetail.keyword_tags = [];
      this.showPost = true;
    },
    // 获取招聘信息详情
    getPostDetail(id) {
      let params = {
        id: id,
      };
      api.getPostDetail(params).then((res) => {
        // console.log(res)
        this.postInfoDetail = res.data;
        this.postInfoDetail.tags = res.data.other_label.split("|");
        this.postInfoDetail.keyword_tags = res.data.key_word.split("|");
      });
    },
    // 获取公司招聘排行
    getPostRank(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.postCompanyLoading = true;
      this.postCompanyCount.legend = [];
      this.postCompanyCount.data = [];
      this.postCompanyCount.name = {};
      api
        .getPostRank(params)
        .then((res) => {
          // console.log("招聘排行", res)
          res.data.forEach((i) => {
            let legend_item =
              i.short_name.length > 4
                ? i.short_name.substring(0, 4) + "..."
                : i.short_name;
            this.postCompanyCount.legend.push(legend_item);
            this.postCompanyCount.name[legend_item] = i.full_name;
            // this.postCompanyCount.data.push(i.num);
            this.postCompanyCount.data.push({
              value: i.count,
              company_id: i.company_id,
            });
          });
          this.postCompanyLoading = false;
        })
        .catch((err) => {
          this.postCompanyLoading = false;
        });
      // console.log("postCompanyCount", this.postCompanyCount)
    },
    // 分页器变化
    handlePostPageChange(val) {
      // console.log("分页", val)
      this.post_params.page = val;
      this.getPostInfo();
    },
    // 获取公司招聘信息
    getPostInfo() {
      // let params = {
      //   company_id: "",
      //   page: 1,
      //   page_size: 10
      // }
      this.postLoading = true;
      api
        .getPostInfo(this.post_params)
        .then((res) => {
          // console.log(res.data)
          this.post_list = res.data;
          this.post_total = res.count;
          this.postLoading = false;
        })
        .catch((err) => {
          this.postLoading = false;
        });
    },
    // 过滤类型切换
    handleTypeChange(val) {
      // console.log(val);
      if (this.patentParams.domain_id) {
        this.patent_list = [];
        this.getIndustryPatent();
      }
    },
    // 获取产业生态位
    async getIndustryPosition() {
      let params = {
        industry: this.patentParams.industry,
      };
      this.positionLoading = true;
      await api.getIndustryPosition(params).then((res) => {
        // console.log("生态位", res);
        this.links_position = {
          upUpStream: [],
          upStream: [],
          mediumStream: [],
          downStream: [],
        };
        res.data.forEach((i) => {
          if (i.chain == 0) {
            if (!i.father_id) {
              this.links_position.upUpStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 1) {
            if (!i.father_id) {
              this.links_position.upStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 2) {
            if (!i.father_id) {
              this.links_position.mediumStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
          if (i.chain == 3) {
            if (!i.father_id) {
              this.links_position.downStream.push({
                name: i.name,
                id: i.id,
                children: [],
              });
            }
          }
        });
        res.data.forEach((i) => {
          if (i.chain == 0 && i.father_id) {
            this.links_position.upUpStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 1 && i.father_id) {
            this.links_position.upStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 2 && i.father_id) {
            this.links_position.mediumStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 3 && i.father_id) {
            this.links_position.downStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
        });
        console.log("数据格式化", this.links_position);
        // setTimeout(() => {
        //   this.lineInit()
        // }, 2000)

        this.positionLoading = false;
      });
      // if (this.patentParams.industry == "ele_car") {
        setTimeout(() => {
          this.lineInit();
        }, 500);
      // }
    },
    // 点击查看评分项明细
    async handleCheckDetail() {
      this.showScoreDetail = await true;
      // setTimeout(() => {
      //   document.getElementsByClassName(
      //     "el-table__body-wrapper is-scrolling-middle"
      //   )[0].scrollLeft = 0;
      // }, 500)
    },
    // 获取公司评分列表
    getCompanyScore() {
      let params = {
        node_id: this.domain_id,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      api.getCompanyScore(params).then((res) => {
        console.log("公司评分列表", res);
        res.data.forEach((i) => {
          if (i.short_name) {
            i.name = i.short_name;
          } else {
            i.name = i.full_name;
          }
          // this.downstreamCompanyList.push(i);
          if (i.country == "中国") {
            this.downstreamCompanyListChina.push(i);
          } else {
            this.downstreamCompanyListGlobal.push(i);
          }
        });
        if (this.mapTabMarket == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
        let arrProvince = new Set();
        let arrCountry = new Set();
        res.data.forEach((i) => {
          if (i.loc_province && i.country == "中国") {
            arrProvince.add(i.loc_province.replace("省", "").replace("市", ""));
          }
          if (i.country && i.country != "中国") {
            arrCountry.add(i.country);
          }
        });
        arrProvince.forEach((i) => {
          latentCompAreaList.push({
            loc_province: i,
            value: 0,
          });
        });
        arrCountry.forEach((i) => {
          globalCompAreaList.push({
            name: i,
            value: 0,
          });
        });
        res.data.forEach((item) => {
          for (let i = 0; i < latentCompAreaList.length; i++) {
            if (
              latentCompAreaList[i].loc_province &&
              latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
            ) {
              latentCompAreaList[i].value += 1;
            }
          }
          for (let i = 0; i < globalCompAreaList.length; i++) {
            if (globalCompAreaList[i].name == item.country) {
              globalCompAreaList[i].value += 1;
            }
          }
        });
      });
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司的专利排行
    getCompanyPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.companyPatentCount.legend = [];
      this.companyPatentCount.data = [];
      this.companyPatentCount.name = {};
      this.barLoading = true;
      let startTime = new Date().valueOf();
      api
        .getCompanyPatentCount(params)
        .then((res) => {
          console.log("公司专利排行", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.companyPatentCount.legend.push(legend_item);
            this.companyPatentCount.name[legend_item] = i.full_name;
            // this.companyPatentCount.data.push(i.num);
            this.companyPatentCount.data.push({
              value: i.num,
              company_guogao_id: i.company_guogao_id,
            });
          });
          this.barLoading = false;
          console.log("companyPatentCount", this.companyPatentCount);
          let endTime = new Date().valueOf();
          console.log(
            `%c专利排行：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.barLoading = false;
        });
    },
    // 公司列表查看明细
    checkDetail(row) {
      console.log("明细", row);
      this.showScoreDetail = true;
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: this.patentParams.company_guogao_id,
      };
      this.keywordLoading = true;
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudData = res.data;
          this.keywordLoading = false;
        })
        .catch((err) => {
          this.keywordLoading = false;
        });
    },
    // 获取产业专利年度统计
    getIndustryPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.patentCountLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentCount(params)
        .then((res) => {
          console.log("年度统计", res);
          this.patentEchartsData = res.data;
          this.patentCountLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业专利年度统计：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentCountLoading = false;
        });
    },
    // 获取产业市场简介
    getIndustryIntroduction() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryIntroduction(params).then((res) => {
        console.log("产业简介", res);
        this.industryDesc = res.data;
      });
    },
    // 加载更多
    showMore() {
      this.patentParams.page += 1;
      this.getIndustryPatent();
    },
    // 获取产业专利
    getIndustryPatent() {
      // this.patent_list = []
      this.patentLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          res.data.forEach((r) => {
            this.patent_list.push(r);
          });
          this.patentLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c获取产业专利：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // this.patent_list = res.data
        })
        .catch((err) => {
          this.patentLoading = false;
        });
    },
    // 选择tab
    handleRadioChange(val) {
      console.log(val);
      if (val == "产业链条") {
        this.getIndustryTree(this.industrySelected);
        this.downstreamCompanyList = this.downstreamCompanyListChina;
        // this.getIndustryCompany(this.domain_id);
      } else if (val == "产业市场") {
        this.getIndustryTree(this.industrySelected);
        this.getIndustryCompany(this.industrySelected);
      } else if (val == "专利技术") {
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = "";
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.patent_list = [];
        console.log("点击专利技术this.patent_list", this.patent_list);
        console.log(
          "点击专利技术this.patentParams.page",
          this.patentParams.page
        );
        console.log(
          "点击专利技术this.patentParams.company_guogao_id",
          this.patentParams.company_guogao_id
        );
        this.getIndustryTree(this.industrySelected);
        this.getIndustryPatent();
        this.getIndustryPatentScatter();
        this.getIndustryPatentCount();
        this.getIndustryPatentKeyword();
        this.getCompanyPatentCount();
      } else if (val == "产业人才") {
        this.post_params.industry_node = "";
        this.post_params.industry = this.patentParams.industry;
        this.getIndustryTree(this.industrySelected);
        this.getPostInfo();
        this.getPostRank("");
        this.getPostScatter("");
        this.getPostMonthRank("");
        this.getPostCount("");
      }
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      arr.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr.splice(num, 1);
    },
    // 获取产业图谱选项
    getIndustryGraphOption() {
      this.optionList = [];
      this.industryImportant = [];
      this.industryImportantObjArr = [];
      let industry_graph_permission = cookie.getCookie("industry_graph");
      api.getIndustryGraphOption().then((res) => {
        console.log("option_res", res);
        // this.optionList = res.data;
        // this.industryImportant.push(res.data[0].name)
        // if (this.patentParams.industry == "ele_car") {
          res.data[0].option_list.forEach((i) => {
            this.industryImportant.push(i.name);
            this.industryImportantObjArr.push(i);
          });
        // }

        // console.log("industryImportant", this.industryImportant);
        let res_data = JSON.parse(JSON.stringify(res.data));
        res.data.forEach((r, idx) => {
          // if (industry_graph_permission.indexOf(r.code) != -1) {
          //   this.optionList.push(r);
          // }
          r.option_list.forEach((o, index) => {
            if (industry_graph_permission.indexOf(o.code) == -1) {
              //   this.optionList.push(o);
              // } else {
              // console.log("else", o);
              // res_data[idx].option_list.splice(index, 1)
              this.deleteArrayObject(res_data[idx].option_list, o.code);
              // console.log("splice", res_data[idx]);
            }
          });
        });
        res_data.forEach((r) => {
          if (r.option_list.length > 0) {
            this.optionList.push(r);
          }
        });
        // this.optionList = res_data
        console.log("optionList", this.optionList);
        console.log("res_data", res_data);
      });
    },
    // 打开网页
    goto(web) {
      window.open(web);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      console.log("val", row);
      let params = {
        full_name: row.full_name,
      };
      await api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.compNameForLogo = info.short_name.substring(0, 4);
        this.chartLinkShortName = info.short_name;
        this.showInfo = true;
      });
      let company_id = await api.getCompanyId(params).then((res) => {
        console.log("获取公司company_id", res.data);
        return res.data;
      });
      this.patentListData = [];
      this.patentParams.company_id = company_id;
      this.getProductTag(company_id);
      this.getLink(company_id);
      this.getFinancialAndProductInfo(company_id);
      this.getHolder(company_id);
      this.getPatentCountAndKeyword(company_id);
      this.getPatentList();
    },
    // 获取下游公司列表
    async getDownstreamCompany(val) {
      let params = {
        category_id: val,
      };
      this.downstreamLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.downstreamCompanyList = [];
      this.downstreamCompanyListChina = [];
      this.downstreamCompanyListGlobal = [];
      await api
        .getIndustryCompany(params)
        .then((res) => {
          console.log("下游", res);
          this.downstreamCompanyList = [];
          this.downstreamCompanyListChina = [];
          this.downstreamCompanyListGlobal = [];
          // this.downstreamCompanyList = res.data
          res.data.forEach((r) => {
            if (r.country == "中国") {
              this.downstreamCompanyListChina.push(r);
            } else {
              this.downstreamCompanyListGlobal.push(r);
            }
          });
          if (this.mapTabMarket == 1) {
            this.downstreamCompanyList = this.downstreamCompanyListChina;
          } else {
            this.downstreamCompanyList = this.downstreamCompanyListGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province &&
                latentCompAreaList[i].loc_province ==
                  item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
          // this.downstreamLoading = false;
        })
        .catch((err) => {
          this.downstreamLoading = false;
          console.error(err);
        });
      // document.getElementsByClassName(
      //   "el-table__body-wrapper"
      // )[0].scrollTop = 0;
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
      this.downstreamLoading = false;
    },
    // 获取产业对应公司列表
    async getIndustryCompany(val) {
      let params = {
        category_id: val,
      };
      this.tableLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGloba = [];
      console.log("tab", this.tab);
      await api
        .getIndustryCompany(params)
        .then((res) => {
          this.tableData = [];
          this.tableDataChina = [];
          this.tableDataGloba = [];
          console.log("产业对应公司列表", res);
          // this.tableData = res.data;
          res.data.forEach((r) => {
            if (r.country == "中国") {
              // this.tableData.push(r)
              this.tableDataChina.push(r);
            } else {
              // this.tableData.push(r)
              this.tableDataGlobal.push(r);
            }
          });
          if (this.tab == 1) {
            this.tableData = this.tableDataChina;
          } else {
            this.tableData = this.tableDataGlobal;
          }
          let arrProvince = new Set();
          let arrCountry = new Set();
          res.data.forEach((i) => {
            if (i.loc_province && i.country == "中国") {
              arrProvince.add(
                i.loc_province.replace("省", "").replace("市", "")
              );
            }
            if (i.country && i.country != "中国") {
              arrCountry.add(i.country);
            }
          });
          arrProvince.forEach((i) => {
            latentCompAreaList.push({
              loc_province: i,
              value: 0,
            });
          });
          arrCountry.forEach((i) => {
            globalCompAreaList.push({
              name: i,
              value: 0,
            });
          });
          res.data.forEach((item) => {
            for (let i = 0; i < latentCompAreaList.length; i++) {
              if (
                latentCompAreaList[i].loc_province ==
                item.loc_province.replace("省", "").replace("市", "")
              ) {
                latentCompAreaList[i].value += 1;
              }
            }
            for (let i = 0; i < globalCompAreaList.length; i++) {
              if (globalCompAreaList[i].name == item.country) {
                globalCompAreaList[i].value += 1;
              }
            }
          });
        })
        .catch((err) => {
          this.tableLoading = false;
        });
      console.log("latentCompAreaList", latentCompAreaList);
      // 重置滚动条
      document.getElementsByClassName(
        "el-table__body-wrapper"
      )[0].scrollTop = 0;
      this.mapLatent = latentCompAreaList;
      this.worldList = globalCompAreaList;
      this.tableLoading = false;
    },
    // 获取产业树
    getIndustryTree(val) {
      let params = {
        root: val,
      };
      this.treeLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryTree(params)
        .then(async (res) => {
          console.log("产业树", res);
          let treeData = [];
          // treeData = [
          //   {
          //     label: val,
          //     id: 0,
          //     children: [

          //     ]
          //   }
          // ]
          res.data.nodes.forEach((i) => {
            if (i.name == val) {
              treeData.push({
                label: val,
                id: i.id,
                children: [],
              });
            }
          });
          console.log("treeData", treeData);
          // res.data.nodes.forEach(async (n) => {
          //   n.category_id = n.id;
          //   n.id = undefined;
          //   n.level_independence = "high";
          //   // 计算第一个节点和1的差值，为了使节点的level整体偏移，因为该关系图默认显示level1和level2的节点
          //   let lv = await res.data.nodes[0].level -1
          //   // console.log("lv", lv)
          //   n.level -=lv
          //   // console.log("level", n.level)
          // });
          // this.nodes = await res.data.nodes;
          // this.links = await res.data.links;
          // // console.log("nodes", this.nodes)
          // // console.log("links", this.links)
          // this.treeLoading = false;
          // let endTime = new Date().valueOf();
          // console.log(
          //   `%c产业树：%c${(endTime - startTime) / 1000}`,
          //   "color: blue;font-weight:bold",
          //   "color: orange;font-weight:bold"
          // );
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 切换全球/中国
    changeGlobalOrChina(val, flag) {
      // flag:1代表产业市场地图,2代表产业链条地图
      if (flag == 1 || flag == 2) {
        this.mapTabMarket = val;
        if (val == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
      }
      if (flag == 2) {
        this.tab = val;
        //   if (val == 1) {
        //     this.tableData = this.tableDataChina;
        //   } else {
        //     this.tableData = this.tableDataGlobal;
        //   }
      }
      if (flag == 3) {
        this.mapTabTech = val;
        // if (val == 1) {
        //   this.tableData = this.tableDataChina;
        // } else {
        //   this.tableData = this.tableDataGlobal;
        // }
      }
      // this.getCompanyByProduct();
    },
    // 获取专利技术地图分布
    getIndustryPatentScatter() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.mapLatentTech = [];
      this.patentScatterLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentScatter(params)
        .then((res) => {
          console.log("专利分布", res);
          this.mapLatentTech = res.data;
          this.patentScatterLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c专利分布：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentScatterLoading = false;
        });
    },
    // 获取产业结构各项数据
    getIndustryStructureData(i) {},
    // 点击产业
    handleClickIndustry(i) {
      console.log("@@@@@@点击产业", i);
      console.log(i.name);
      this.isFirst = false;
      // this.getCategoryLink(text);
      this.industrySelected = i.name;
      this.patentParams.industry = i.industry_en;
      console.log("industry_en", i.industry_en);
      this.domain_id = i.domain_id;
      this.patentParams.domain_id = i.domain_id;
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGlobal = [];
      // this.getIndustryTree(i.name);
      this.getIndustryPosition();
      this.getIndustryIntroduction();
      this.getCompanyScore();
      console.log("产业", i);
      // this.getDownstreamCompany(i.downstream_id);
      // if (i.name == "新能源汽车" || i.name == "热泵空调") {
      //   this.link_industry = ev;
      // } else if (i.name == "疫苗") {
      //   this.link_industry = yiliao;
      // } else if (i.name == "呼吸机") {
      //   this.link_industry = yiliaoqixie;
      // }
      // this.getIndustryCompany(i.domain_id);
      // this.getCompanyByCategory(i.text);
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置");
        this.isFirst = true;
        // this.tabRadio = "产业市场";
        this.showPosition = false
        this.mapTabMarket = 1;
        this.tab = 1;
        this.patentParams.company_guogao_id = "";
        this.patent_list = [];
        this.getIndustryGraphOptionRadar();
      });
    },
    // 监听图谱节点点击
    listenIndustryGraph() {
      EventBus.$on("industry_graph", (data) => {
        console.log("industry_graph", data);
        // this.getCompanyByCategory(data.industry_node);
        if (this.tabRadio == "产业链条") {
          this.nodeDesc = data.desc;
          // this.getIndustryCompany(data.domain_id);
          this.domain_id = data.domain_id;
          this.getCompanyScore();
        }
        if (this.tabRadio == "专利技术") {
          this.patent_list = [];
          this.patentParams.page = 1;
          this.patentParams.company_guogao_id = "";
          this.patentParams.domain_id = data.domain_id;
          this.getIndustryPatentCount();
          this.getIndustryPatent();
          this.getIndustryPatentScatter();
          this.getCompanyPatentCount();
          this.getIndustryPatentKeyword();
        }
        if (this.tabRadio == "产业人才") {
          this.post_params.industry_node = data.domain_id;
          this.getPostScatter(data.domain_id);
          this.getPostRank(data.domain_id);
          this.getPostMonthRank(data.domain_id);
          this.getPostCount(data.domain_id);
          this.getPostInfo();
        }
      });
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", (data) => {
        console.log("监听源产业变化", data);
        this.patentParams.industry = data.industry_en;
        this.handleClickIndustry(data);
        this.tabRadio = "产业市场";
      });
    },
    // 监听产业人才排行
    listenCompanyPostBar() {
      EventBus.$on("company_post_bar", (data) => {
        this.post_list = [];
        this.post_params.page = 1;
        this.post_params.company_id = data;
        this.post_params.post_classification = "";
        this.getPostInfo();
      });
    },
    // 监听排行bar变化
    listenCompanyPatentBar() {
      EventBus.$on("company_patent_bar", (data) => {
        console.log("监听排行bar变化", data);
        this.patent_list = [];
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = data;
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.getIndustryPatent();
        this.getIndustryPatentKeyword();
      });
    },
    // 监听index_industry
    listenIndexIndustry() {
      EventBus.$on("index_industry", (data) => {
        console.log("+++++++++++", data);
        this.handleClickIndustry(data);
      });
    },
  },
  mounted() {
    console.log("links", ev.links);
    this.env = process.env.VUE_APP_CURRENTMODE
    console.log("当前环境", this.env)
    // console.log(
    //   "industry",
    //   JSON.parse(sessionStorage.getItem("index_industry"))
    // );
    // this.getIndustryTree("新能源汽车")
    // this.newsParams.permissions = sessionStorage.getItem("permissions");
    this.getIndustryGraphOption();
    this.permissions = cookie.getCookie("permissions");
    cookie.setCookie("serverValue", "产业结构");
    // this.handleClickIndustry(
    //   JSON.parse(sessionStorage.getItem("index_industry"))
    // );
    this.getIndustryGraphOptionRadar();
    this.listenReset();
    this.listenIndustryGraph();
    this.listenSourceIndustry();
    this.listenCompanyPatentBar();
    this.listenCompanyPostBar();
    this.listenCompanyPostRank();
    this.listenIndexIndustry();
    // this.getCategoryLink()
  },
  beforeDestroy() {
    EventBus.$off("reset");
    EventBus.$off("industry_graph");
    EventBus.$off("choose_source_industry");
    EventBus.$off("company_post_bar");
    EventBus.$off("company_patent_bar");
    EventBus.$off("company_post_rank");
    EventBus.$off("index_industry");
  },
};
</script>

<style lang="scss">
.industryStructure {
  // margin-top: 52px;
  // width: 100%;
  .context-menu-list {
    color: black;
  }
  width: calc(100vw - 64px);
  min-height: calc(100vh - 60px -50px);
  overflow-y: scroll;
  background-color: white;
  // margin-top: 50px;
  padding-bottom: 10px;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  display: flex;
  justify-content: center;
  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }
  .el-form-item {
    margin-bottom: 2px;
  }
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    // margin-top: 4px;
    border-radius: 6px;
    justify-content: center;
  }
  .industryBox {
    width: 60vw;
    // height: 30vw;
    // margin-left: 9.5vw;
    margin-top: 1vw;
    // border: 1px solid black;
    flex-wrap: wrap;
    // justify-content: space-between;
    display: flex;
    // .not_online {
    //   filter: brightness(30%);
    // }
    &-item {
      width: 10vw;
      height: 10vw;
      position: relative;
      margin-left: 1vw;
      cursor: pointer;
      margin-bottom: 1vw;
      // background-color: #333333;
      img {
        width: 100%;
        height: 100%;
        filter: brightness(50%);
      }
    }
    &-text {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      top: 0;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
    }
  }
  .selectType {
    position: absolute;
    z-index: 10;
    top: 24vh;
    left: 6.5vw;
    .img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }
      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }
      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  tr:first-of-type {
    background-color: white;
  }
  #companyTable {
    margin-top: 20px;
  }
  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;
    // min-height: 0vh;
    border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    table {
      width: 100%;
      color: #333333;
      margin-left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }
      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }
      td:nth-child(even) {
        width: 20%;
      }
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 5px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
    .detail {
      width: 100%;
      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }
      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }
  .el-dialog__body {
    padding-top: 10px;
  }
  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }
  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }
  table {
    margin-left: 0;
  }
  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    &-top {
      // width: 98%;
      .industryDesc {
        margin-top: 10px;
        width: 61.5vw;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }
  .tech {
    width: 100%;
    &-top {
      display: flex;
    }
  }
  .industryLink_up {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      // color: #3E43F4;
      background-color: #3E43F4;
      color: white;
      cursor: pointer;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-noChild {
        border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      // color: #3E43F4;
      background-color: #3E43F4;
      color: white;
      cursor: pointer;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }
      &-headSelected {
        border-color: #3E43F4;
        // border: 2px solid red;
        // color: red;
        cursor: pointer;
        background-color: #3E43F4;
        color: white;
      }
      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink_medium {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      margin-left: -2;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      // color: #3E43F4;
      background-color: #3E43F4;
      color: white;
      cursor: pointer;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }
      &-headSelected {
        border-color: red;
        // border: 2px solid red;
        // color: red;
        background-color: red;
        color: white;
        cursor: pointer;
      }
      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink_down {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      // color: #3E43F4;
      background-color: #3E43F4;
      color: white;
      cursor: pointer;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }
      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }
  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: #d8d7d7;
      }
    }
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }
  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }
    &-company {
      font-size: 16px;
      margin-top: 5px;
    }
    &-info {
      margin-top: 25px;
    }
    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }
    &-position_info {
      margin-top: 20px;
    }
    &-url {
      margin-top: 10px;
    }
  }
  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
</style>
